<template>
	<router-view :key="$route.params.tipo"/>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card p-fluid" id="xyz">
				<h4 class="p-m-2">{{ titulo }}</h4>
				<Toast/>		
				<Toolbar class="p-mb-4">
					<template v-slot:left>						
							<TabView v-model:activeIndex="tabIndex">								
								<TabPanel header="General">
									<div class="p-fluid p-formgrid p-grid">									
										<div class="p-field p-col-3">
											<label for="Periodo">Período</label>
											<SeleccionFecha @nuevoPeriodo="actualizarPeriodo"/>
										</div>
										<div class="p-field p-col-2 p-md-4">
											<label for="desde">Desde</label>
											<Calendar id="desde" v-model="filtro.desde" :key="periodoUpdate"/>
										</div>		
										<div class="p-field p-col-3 p-md-4">
											<label for="hasta">Hasta</label>
											<Calendar id="hasta" v-model="filtro.hasta" :key="periodoUpdate"/>
										</div>											
										<div class="p-field p-col-6">
											<div v-if="appMode==0" class="p-field p-m-1">
												<label for="cuentahabiente">Cuentahabiente</label>
												<div class="p-fluid p-inputgroup">
												<InputText size="135" id="cuentahabiente" type="text" v-model="CtahabienteNombre" disabled/>																												
												<Button v-if="mostrarBotonBusquedaCtaHabiente" icon="pi pi-search" @click="buscarCtahabiente"/>							
												<Button v-if="!mostrarBotonBusquedaCtaHabiente" icon="pi pi-times" class="p-button-warning" @click="buscarCtahabiente"/>							
												</div>
											</div>
											<div v-if="appMode==1" class="p-field p-m-1">
											<label for="inmueble">Inmueble</label>
											<div class="p-inputgroup">
												<InputText size="135" id="inmueble" type="text" v-model="InmuebleDisplay" disabled/>																												
												<Button v-if="mostrarBotonBusquedaCtaHabiente" icon="pi pi-search" @click="seleccionarInmueble"/>							
												<Button v-if="!mostrarBotonBusquedaCtaHabiente" icon="pi pi-times" class="p-button-warning" @click="seleccionarInmueble"/>							
											</div>					
											</div>		
										</div>	
										<div class="p-field p-col-3 p-mt-1">
											<label for="Moneda">Moneda</label>
											<Dropdown v-model="IdMoneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" placeholder="Seleccionar"/>							
										</div>												
										<div class="p-field-checkbox p-col-3 p-mt-4">
											<label for="anulados">Mostrar Anulados</label>
											<Checkbox id="anulados" class="p-ml-2" v-model="mostrar_anulados" :binary="true" />							
										</div>														
										<div class="p-field p-col-2 p-md-2">
											<label for="buscar"></label>
											<Button label="Buscar" icon="buscarCtaHabienteIcon" :key="CtaHabienteUpdate" class="p-button-primary p-m-2" @click="buscar" />								
										</div>		
										<div class="p-field p-ml-4 p-mt-1" style="font-size:12px">
											<p><strong>NOTA:</strong> Por razones de desempeño, esta consulta muestra hasta un máximo de 500 transacciones. Si desea visualizar el total de transacciones, del período seleccionado, marque esta opción</p>
											<label for="full_batch">Visualizar más de 500 transacciones.</label>
											<Checkbox id="full_batch" v-model="filtro.full_batch" :binary="true" />							
										</div>
									</div>
								</TabPanel>
								<TabPanel header="No. de documento y Serie">
									<div class="p-fluid p-formgrid p-grid">
										<div class="p-field p-col-4 p-m-2">
											<label for="serie">Serie</label><span class="p-error">*</span>
											<InputText id="serie" v-model="filtro.serie" style="text-transform: uppercase"/>
										</div>		
										<div class="p-field p-col-4 p-m-2">
											<label for="numero">Número</label><span class="p-error">*</span>
											<InputNumber id="numero" v-model="filtro.no_docto" :useGrouping="false"/>
										</div>	
										<div class="p-field p-col-4 p-m-2">
											<label for="buscar"></label>
											<Button label="Buscar" icon="buscarCtaHabienteIcon" :key="CtaHabienteUpdate" :disabled="!puedeBuscarPorSerieDocto" class="p-button-primary p-m-2" @click="buscar" />								
										</div>												
									</div>									
								</TabPanel>							
							</TabView>																																																				
					</template>
				</Toolbar>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Enviar" icon="pi pi-envelope" class="p-button-secondary" @click="confirmarEnvioEmail" :disabled="!puedeEnviarEmails"/>
						<SplitButton v-if="puedeDescargar" label="Descargar" icon="pi pi-download"  class="p-m-2" :model="dowloandItems" />
					</template>
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="nuevoTrx" />				
					</template>
				</Toolbar>		
				<DataTable v-model:filters="filters" @filter="filter" filterDisplay="row" ref="dt" :value="trxs" class="p-datatable-sm" :rowClass="rowClass" v-model:selection="selectedMovs" dataKey="id_movimiento" :paginator="true" :rows="10"
							:globalFilterFields="['documento', 'fel_doc', 'cta_habiente', 'nit', 'referencia']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loading">	
					<!-- <template #header>
						<div class="table-header p-d-flex p-flex-column p-jc-md-between">
							<span class="p-input-icon-right">
								<i class="pi pi-search" />
								<InputText v-model="filters['global'].value" placeholder="Buscar..." />
							</span>
						</div>
					</template>		 -->
					<Column selectionMode="multiple" headerStyle="width: 3rem"/>											
					<Column field="fecha" header="Fecha" :sortable="true" headerStyle="width: 7rem" >
						<template #filter="{ filterModel, filterCallback }">
							<InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
						</template>
					</Column>	
					<Column field="documento" header="#Docto" :sortable="true" headerStyle="width: 10rem" >
						<template #filter="{ filterModel, filterCallback }">
							<InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
						</template>
					</Column>					
					<Column field="fel_doc" header="#FEL" :sortable="true" headerStyle="width: 15rem" :style="esFel? '' : 'display: none'">
						<template #filter="{ filterModel, filterCallback }">
							<InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
						</template>					
					</Column>										
					<Column field="cta_habiente" filterField="cta_habiente" :header="tituloCtaHabiente" :sortable="true" headerStyle="width: 35rem">
						<template #filter="{ filterModel, filterCallback }">
							<InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
						</template>
					</Column>
					<Column field="nit" filterField="nit" header="NIT" :sortable="true" headerStyle="width: 7rem" :style="appMode==0? '' : 'display: none'">
						<template #filter="{ filterModel, filterCallback }">
							<InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
						</template>					
					</Column>										
					<Column field="referencia" filterField="referencia" header="Referencia" :sortable="true" headerStyle="width: 15rem">
						<template #filter="{ filterModel, filterCallback }">
							<InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
						</template>					
					</Column>	
					<Column field="total" header="Monto" :sortable="true" headerStyle="width: 10rem" >					
						<template #body="slotProps">
							<div class="p-d-flex p-jc-end">
								<div>{{ formatCurrency(slotProps.data.id_moneda, slotProps.data.total) }}</div>
							</div>   					
						</template>		
						<template #filter="{ filterModel, filterCallback }">
							<InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
						</template>							
					</Column>
					<Column headerStyle="width: 3rem">
						<template #body="slotProps">							
							<Button v-if="puedeImprimir && (infoTipo.fmts.length == 1)" text="Imprimir" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="descargarPDF(slotProps.data, infoTipo.fmts[0].referencia)" />
							<Button v-if="puedeImprimir && (infoTipo.fmts.length > 1)" text="Imprimir" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="slotProps.data.event = $event; showFormatos(slotProps.data)" />
						</template>
					</Column>					
					<Column headerStyle="width: 3rem">
						<template #body="slotProps">							
							<Button text="Ver" icon="pi pi-eye" class="p-button-rounded p-button-success p-mr-2" @click="verTrx(slotProps.data)" />							
						</template>
					</Column>
					<Column headerStyle="width: 3rem">
						<template #body="slotProps">							
							<Button v-if="puedeAnular && slotProps.data.anulado == 'N'" text="Anular" icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" @click="confirmarAnulacionMov(slotProps.data)" />
						</template>
					</Column>
					<ColumnGroup type="footer">
						<Row>
							<Column v-if="(IdMonedaSel != '0') && (filtro.serie=='')" footer="Total:" :colspan="7" footerStyle="text-align:right" />
							<Column v-if="(IdMonedaSel != '0') && (filtro.serie=='')" :footer="formatCurrency(IdMonedaSel, totalDocumentos)" footerStyle="text-align:left"/>			
						</Row>
					</ColumnGroup>							
				</DataTable>

				<BuscarCtaHabiente :key="dlgBuscarCtaHabienteRefresh" :mostrar="dlgBuscarCtaHabiente" @cancelar="cerrarDlgCtahabiente" @seleccionar="selCtaHabiente"/>
				<MdsBuscarInmueble :key="dlgBuscarCtaHabienteRefresh" :mostrar="inmuebleDialog" @cancelar="cerrarDlgInmueble" @seleccionar="elegirInmueble"/>
				
				<OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 450px" :breakpoints="{'960px': '75vw'}">
					<DataTable :value="infoTipo.fmts" v-model:selection="selFormato" selectionMode="single" :paginator="false" :rows="5" @rowSelect="onFormatoSelect" responsiveLayout="scroll" >
						<Column field="nombre" header="Seleccionar Formato" sortable style="width: 50%">
							<template #body="{data}">
								<i v-if="data.referencia!='FEL-XML'" class="pi pi-file-pdf" style="font-size: 1rem; color: red"></i>
								<i v-else class="pi pi-file" style="font-size: 1rem; color: blue"></i>
								{{ data.nombre }}
							</template>							
						</Column>
					</DataTable>
				</OverlayPanel>

				<Dialog v-model:visible="anularDialog" :style="{width: '450px'}" header="Confirmar Anulación" :modal="true">
					<div class="p-inputgroup"> 
					<div class="p-d-flex"><b>{{infoTipo.tipo}} [{{mov.documento}}]</b></div>
					</div>
					<div class="p-d-flex">Por favor indique la razón de esta anulación:</div>						
					<div class="p-d-flex">					
					<Textarea v-model="razonAnulacion" rows="2" cols="125" />
					</div>
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="anularDialog = false"/>
						<Button label="Continuar" icon="pi pi-check" class="p-button-danger" @click="anularTrx" :disabled="puedeOperarAnulacion"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="envioNotificacionesDlg" :showHeader="true" :modal="true" >
					<template #header>
						Confirmación de envío de notificación
					</template>
					<div class="p-d-flex">
						<i class="pi pi-envelope p-mr-2" style="fontSize: 2rem"></i>
						<p>¿Está seguro que desea enviar los documentos seleccionado(s)?</p>
					</div>
					<!-- <div v-if="esFel" class="p-d-flex p-ai-center p-jc-center p-mt-3">
						<SelectButton v-model="formato_email_sel" :options="formatos_email" dataKey="formato" optionValue="formato" aria-labelledby="basic">
							<template #option="slotProps">
								<i class="slotProps.option.icon"></i>
								{{ slotProps.option.formato }}
							</template>
						</SelectButton>
					</div>					 -->
					<template #footer>
						<div class="p-field p-col">
							<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="envioNotificacionesDlg=false"/>
							<Button label="Enviar" icon="pi pi-send" class="p-button-text" @click="enviarACtahabientesSeleccionados"/>
						</div>
					</template>
				</Dialog>

				<Dialog v-model:visible="anulandoTrxDlg" :modal="true" :closable="false" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '35vw'}">					            
					<div class="flex align-content-center flex-wrap card-container">
						<div class="p-d-flex p-ai-center p-jc-center p-mb-2">
							<ProgressSpinner style="width:50px;height:50px"/>
						</div>
						<div class="p-d-flex p-ai-center p-jc-center p-mb-2">
						<p>Anulando transacción...</p>
						</div>
					</div>
				</Dialog>
				
				<Dialog v-model:visible="enviandoEmailsDlg" :showHeader="true" :modal="true" :closable="false">			
					<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
						<div class="p-d-flex p-ai-center p-jc-center">
							<i class="pi pi-send p-m-2" style="font-size: 2rem"></i>
						</div>
						<div class="flex align-items-center justify-content-center mt-5">				
						<ProgressBar :value="secuencia">										
							<div class="font-light">
							Enviando {{secuencia}} de {{selectedMovs.length}}
							</div>
						</ProgressBar>
						</div>
					</div>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import SeleccionFecha from '../components/SeleccionFecha.vue';
import BuscarCtaHabiente from '../components/BuscarCtaHabiente.vue';
import MdsBuscarInmueble from '../components/MdsBuscarInmueble.vue';
import InvService from '../service/inv.service';
import MdsService from '../service/mds.service';
import { CURRENT_APP_MODE } from '../service/constants';
import { read, utils, writeFileXLSX } from 'xlsx';

export default {
	setup() {
		const rowClass = (data) => {
            return data.anulado === 'S' ? 'row-anulada': null;
        };

		return { rowClass }
	},	
	props: {
		tipo: {
			type: String,
			required: true,			
		},
	},
	components: { SeleccionFecha, BuscarCtaHabiente, MdsBuscarInmueble },
	data() {
		return {
			appMode: CURRENT_APP_MODE,
			mostrar_anulados: false,
			periodoUpdate: 0,
			trx: this.$store.state.auth.currentAppCtx.trxs.find(t => t.tipo == this.tipo.toUpperCase()),
			filtro: this.resetFiltro(),
			tituloCtaHabiente: (CURRENT_APP_MODE == 1? 'Inmueble': 'Cta.Habiente'),
			CtaHabienteUpdate: 0,
			CtahabienteNombre: '(Todos)',
			infoTipo: {fmts:[]},
			esFel: false,
			selMov: null,
			selFormato: null,	
			trxs: null,
			filteredData: [],
			tabIndex: 0,
			dlgBuscarCtaHabiente: false,
			dlgBuscarCtaHabienteRefresh: 0,
			InmuebleDisplay: '(Todos)',
			inmuebleDialog: false,					
			selIdCtaHabienteInfo: null,
			selectedMovs: null,
			secuencia: 1,
			razonAnulacion: null,
			anularDialog: false,
			anulandoTrxDlg: false,
			puedeAnular: this.$store.getters['auth/tieneAcceso']('VF_ANULAR_TRX'),
			puedeImprimir: (this.$store.getters['auth/tieneAcceso']('VF_IMPR_TRX')),
			puedeEnviarEmail: (this.$store.getters['auth/tieneAcceso']('VF_IMPR_TRX')),
			puedeDescargar: (this.$store.getters['auth/tieneAcceso']('TRX_DESCARGAR')),
			dowloandItems: [	
    			{label: 'Exportar Listado Docs', icon: 'pi pi-file-excel', command: () => {this.exportarTrxsAXLS()}},
    			{label: 'Descargar Docs', icon: 'pi pi-file-pdf', command: () => {this.prepararBatchDocs()}}				
			],
			enviandoEmailsDlg: false,
			envioNotificacionesDlg: false,
			formato_email_sel: "PDF",
			formatos_email: [{formato:"PDF", icon:"pi pi-file-pdf"}, {formato:"FEL-XML", icon:"pi pi-file"}],
			mov: {},
			lkpSucursales: this.$store.state.auth.currentAppCtx.Sucursales,			
			lkpTiposProducto: null,
			loading: false,
			lkpMonedas: [],
			IdMoneda: '0',	
			IdMonedaSel: '0',			
			filters: {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				fecha: {value: null, matchMode: FilterMatchMode.CONTAINS},
				fel_doc: {value: null, matchMode: FilterMatchMode.CONTAINS},
				documento: {value: null, matchMode: FilterMatchMode.CONTAINS},
				nit: { value: null, matchMode: FilterMatchMode.CONTAINS },
				cta_habiente: { value: null, matchMode: FilterMatchMode.CONTAINS },
				referencia: { value: null, matchMode: FilterMatchMode.CONTAINS },
				total: { value: null, matchMode: FilterMatchMode.EQUALS }
			},
			submitted: false
		}
	},
	mounted() {		
		this.lkpMonedas = [...this.$store.state.auth.currentAppCtx.Monedas];
		// this.lkpMonedas = this.$store.state.auth.currentAppCtx.Monedas;
		
		if(this.lkpMonedas.length > 1) {
			this.lkpMonedas.splice(0, 0, {id_moneda: '0', moneda: '(Todas)'});
		} else {
			this.IdMoneda = this.lkpMonedas[0].id_moneda;
		}

		this.GetInfoTipo();
	},
	watch: {
		tipo(value)
		{
			this.trx = this.$store.state.auth.currentAppCtx.trxs.find(t => t.tipo == value.toUpperCase());
			this.trxs = null;
			this.resetFiltro();			
			this.GetInfoTipo();		
		}
	},
	computed: 
	{
		titulo(){
			return this.trx.titulo.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())
		},
		tipoTrx(){
			return this.trx.tipo;
		},
		puedeOperarAnulacion(){
			let razon = this.razonAnulacion.trim();
			return !((razon != '') && (this.puedeAnular));
		},
		mostrarBotonBusquedaCtaHabiente(){
			return (this.filtro.id_cta_habiente_info == '*')
		},
		puedeEnviarEmails() {
			if(this.selectedMovs)
			{
				return (this.selectedMovs.length > 0) && this.puedeEnviarEmail;
			} else {
				return false;
			}
		},
		puedeBuscarPorSerieDocto() {
			return (this.filtro.no_docto != '') && (this.filtro.serie != '');
		},
		totalDocumentos() {
			var totalAcumulado = 0.0;

			if (this.IdMonedaSel == '0') {
				return 0.00;
			}

			var docs = null;
			
			if(this.filteredData) {
				docs = this.filteredData;
			} else {
				docs = this.trxs;	
			}

			if(docs) {
				for(let doc of docs) {
					if(doc.anulado == 'N') {
						totalAcumulado += doc.total;
					};
				}
			}
		
			return totalAcumulado;
		}		
	},
	methods: {	
		nuevoTrx() {
			this.$router.push({name: 'trx', params: {tipo: this.tipo, titulo: this.titulo, id: ''}});
		},
		verTrx(trx) {
			this.$router.push({name: 'trx', params: {tipo: this.trx.tipo, titulo: this.titulo, id: trx.id_movimiento}});			
		},
		actualizarPeriodo(periodo)
		{
			this.filtro.desde = periodo.desde;
			this.filtro.hasta = periodo.hasta;
			this.periodoUpdate += 1;
		},
		showFormatos(data) {
			this.selMov = data;
            this.$refs.op.toggle(data.event);
        },
		onFormatoSelect(event)
		{
			this.$refs.op.toggle(event);
			if(this.selFormato.referencia == "FEL-XML") {
				this.descargarFELXml(this.selMov);
			} else {
				this.descargarPDF(this.selMov, this.selFormato.referencia);
			}			
		},
		buscarCtahabiente() {
			if(this.filtro.id_cta_habiente_info == '*'){								
				this.dlgBuscarCtaHabiente = true;
				this.dlgBuscarCtaHabienteRefresh++;
			} else {
				this.CtahabienteNombre = '(Todos)';
				this.filtro.id_cta_habiente_info = '*';		
				//this.CtaHabienteUpdate += 1;		
				//this.$refs.btnCuentaHabiente.icon = "pi pi-search";			
			}
		},
		selCtaHabiente(CtaHabiente)
		{
			this.filtro.id_cta_habiente_info = CtaHabiente.id_cta_habiente_info;			
			this.CtahabienteNombre = CtaHabiente.nombre_comercial;
			//this.CtaHabienteUpdate += 1;		
			this.dlgBuscarCtaHabiente = false;
		},
		cerrarDlgCtahabiente() {
			this.dlgBuscarCtaHabiente = false;
		},
		buscar(){
			this.loading = true;
			this.filtro.anulado = this.mostrar_anulados?"S":"N";

			if (this.tabIndex==0) {
				this.filtro.serie = '';
				this.filtro.no_docto = '';
			} else {
				this.filtro.serie = this.filtro.serie.toUpperCase();				
			}
			
			this.filtro.id_moneda = this.IdMoneda,			
			
			this.trxs = null;
			this.filteredData = null;			
			InvService.getTrxs(this.$store.state.auth.currentAppCtx, this.filtro).then(data => {
				this.trxs = data;
				this.IdMonedaSel = this.IdMoneda;				
				this.loading = false;
			}).catch(err => {
				this.trxs = [];
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
			}
			);	
		},
		async prepararBatchDocs(){
			this.loading = true;
			this.filtro.anulado = "S";
			try {
				var response = await InvService.prepararBatchDocs(this.$store.state.auth.currentAppCtx, this.filtro);

				//Create a Blob from the PDF Stream
				const file = new Blob(
				[response.data], 
				{type: 'application/zip'});
				//Build a URL from the file
				const fileURL = URL.createObjectURL(file);	

				var link = document.createElement('a');
				link.href = fileURL;
				link.download = `${this.titulo}.zip`;
				link.dispatchEvent(new MouseEvent('click'));

				this.loading = false;
			} catch (err)
			{
				this.trxs = [];
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})	
			};
		},
		filter(e){
			this.filteredData = e.filteredValue;
		},
		async exportarTrxsAXLS()
		{
			this.loading = true;
			try {
				var data;
				if(this.filteredData.length > 0) {
					data = this.filteredData;
				} else {
					data = this.$refs.dt.value;
				}
				var rows;

				if(this.appMode==0) {
					rows = data.map(doc => {
						return {
								"Fecha": doc.fecha,	
								"Documento": doc.documento,
								"FEL Doc": doc.fel_doc,
								"Ctahabiente": doc.cta_habiente,								
								"Moneda": doc.moneda,
								"TipoDoc": doc.tipo_doc,
								"NIT": doc.nit,
								"Nombre": doc.nombre,
								"Direccion": doc.direccion,
								"Referencia": doc.referencia,
								"Tasa Cambio": doc.tasa_cambio,
								"Sub Total": doc.sub_total,
								"Total": doc.total,
								"Fecha Pago": doc.fecha_pago,
								"Fecha Creacion": doc.fecha_creacion,
								"Anulado": doc.anulado,
								"Razon Anulacion": doc.razon_anulacion,
								"Usuario": doc.usuario,		
								"Id.Interno": doc.id_movimiento
								}
					}).flat();
				} else {
					rows = data.map(doc => {
						return {
								"Fecha": doc.fecha,	
								"Documento": doc.documento,
								"FEL Doc": doc.fel_doc,								
								"Inmueble": doc.cta_habiente,								
								"Moneda": doc.moneda,
								"TipoDoc": doc.tipo_doc,
								"NIT": doc.nit,
								"Nombre": doc.nombre,
								"Direccion": doc.direccion,
								"Referencia": doc.referencia,
								"Tasa Cambio": doc.tasa_cambio,
								"Sub Total": doc.sub_total,
								"Total": doc.total,
								"Fecha Pago": doc.fecha_pago,
								"Fecha Creacion": doc.fecha_creacion,
								"Anulado": doc.anulado,
								"Razon Anulacion": doc.razon_anulacion,
								"Usuario": doc.usuario,		
								"Id.Interno": doc.id_movimiento
						}
					}).flat();
				}
  				const ws = utils.json_to_sheet(rows);
				var wb = utils.book_new();
				utils.book_append_sheet(wb, ws, `${this.id_moneda} - Transacciones`);
				writeFileXLSX(wb, "Transacciones.xlsx");

				this.loading = false;
			} catch (error)
			{
				this.loading = false;				
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})								
			}	
		},
		formatCurrency(moneda, value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: moneda});
			return;
		},
		GetInfoTipo() {
			this.loading = true;
			this.filtro.id_tipo_doc = this.trx.tipo;
			this.filtro.serie = '';
			this.filtro.no_docto = null;

			InvService.getInfoMovTipo(this.$store.state.auth.currentAppCtx, this.filtro.id_tipo_doc).then(data => {
				this.infoTipo = data;
				this.esFel =  (this.infoTipo.es_fel=="S");
				this.ocultarColumnaFEL = !this.esFel;
				this.puedeImprimir = (this.$store.getters['auth/tieneAcceso']('VF_IMPR_TRX')) && (this.infoTipo.fmts.length > 0);			
				this.buscar();				
			}).catch(err => {
				this.infoTipo = {};
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
			}
			);	
		},
		resetFiltro(){
			return {
				id: this.$store.state.auth.currentAppCtx.id_referencia,
				id_sucursal: this.$store.state.auth.currentAppCtx.Sucursales[0].id_sucursal,
				id_cta_habiente_info: '*',
				anulado: 'N',
				id_tipo_doc: '',
				serie: '',
				id_moneda: '0',
				no_docto: null,
				desde: new Date(),
				hasta: new Date(),
				full_batch: false
			};
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		confirmarAnulacionMov(movimiento) {
			this.mov = movimiento;
			this.razonAnulacion = '';
			this.anularDialog = true;
		},
		anularTrx() {
			this.anularDialog = false;
			this.anulandoTrxDlg = true;
			InvService.anularMovimiento(this.$store.state.auth.currentAppCtx, this.mov, this.razonAnulacion).then(() => {
					this.anularDialog = false;
					this.mov.anulado = 'S';
					if(!this.mostrar_anulados){
						this.trxs = this.trxs.filter(val => val.id_movimiento !== this.mov.id_movimiento);					
						this.mov = {};
					}
					this.$toast.add({severity:'success', summary: 'Exito', detail: 'La transacción ha sido anulada', life: 3000})
					this.anulandoTrxDlg = false;
			}
			).catch(
				data => {
					this.anulandoTrxDlg = false;
					this.mov = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			)
		},
		descargarPDF(movimiento, referencia) {	
			InvService.getFormatoImpresionTrx(this.$store.state.auth.currentAppCtx, movimiento, referencia, false).then(
				response => {
					//Create a Blob from the PDF Stream
					const file = new Blob(
					[response.data], 
					{type: 'application/pdf'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					if(this.infoTipo.es_fel == 'N'){
						link.download = `${movimiento.cta_habiente}(${movimiento.documento}).pdf`;
					} else {
						link.download = `${movimiento.cta_habiente}(${movimiento.fel_doc}).pdf`;
					}
					link.dispatchEvent(new MouseEvent('click'));
				}				
			).catch(
				data => {
					this.anularDialog = false;
					this.mov = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		descargarFELXml(movimiento) {
			this.loading = true;
			InvService.getTrxFELXml(this.$store.state.auth.currentAppCtx, movimiento.id_movimiento).then(
				response => {
					this.loading = false;
					//Create a Blob from the PDF Stream
					const file = new Blob(
					[response.data], 
					{type: 'application/xml'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					if(this.infoTipo.es_fel == 'N'){
						link.download = `${movimiento.nombre}(${movimiento.documento}).xml`;
					} else {
						link.download = `${movimiento.nombre}(${movimiento.fel_doc}).xml`;
					}
					link.dispatchEvent(new MouseEvent('click'));
				}				
			).catch(
				data => {
					this.loading =  false;					
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			)
		},
		seleccionarInmueble() {
			if(this.filtro.id_cta_habiente_info == '*'){								
				this.inmuebleDialog = true;
				this.dlgBuscarCtaHabienteRefresh++;
			} else {
				this.InmuebleDisplay = '(Todos)';
				this.filtro.id_cta_habiente_info = '*';				
			}			
		},
		cerrarDlgInmueble(){
			this.inmuebleDialog = false;
		},					
		elegirInmueble(Inmueble){			
			this.inmuebleDialog = false;
			this.InmuebleDisplay = Inmueble.display;

			this.filtro.id_cta_habiente_info = Inmueble.id_cta_habiente_info;			
			//this.CtaHabienteUpdate += 1;		
			this.inmuebleDialog = false;
		},	
		confirmarEnvioEmail(){
			console.log('Show Dlg');
			this.envioNotificacionesDlg = true;
		},
		async enviarACtahabientesSeleccionados()
		{
			this.envioNotificacionesDlg	= false;
			try
			{
				this.enviandoEmailsDlg = true;
				this.secuencia = 1;
				var notif;

				for(var i = 0; i < this.selectedMovs.length; i++)
				{												
					notif = {						
						id: this.selectedMovs[i].id_movimiento,
						emails: []
					};

					if(this.appMode == 1) { // Multidomus
						notif.emails =  await MdsService.getInmueblesEmailsNotificacion(this.$store.state.auth.currentAppCtx, this.selectedMovs[i].id_cta_habiente_info);
					} else {
						notif.emails = await InvService.getCtaHabienteEmailsNotificacion(this.$store.state.auth.currentAppCtx, this.selectedMovs[i].id_cta_habiente_info);
					}

					if(notif.emails.length > 0) {
						await InvService.enviarEmailTrx(this.$store.state.auth.currentAppCtx, notif);
					}

					this.secuencia++;
				}
				this.secuencia = 1;
				this.enviandoEmailsDlg = false;
			}
			catch(error) {
				this.dlgEnvioEmail = false;
				console.log(error);
			}
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}

::v-deep(.row-anulada) {
    background-color: rgba(0,0,0,.15) !important;
}
</style>    

